import React from "react";
import './writing.css';
import JFResumeArrow from './images/JFResumeArrow.png';
import Stern from './images/Stern.png';
import Quanterix from './images/Quanterix.jpeg';
import PPH from './images/PPH.png';
import Phosphorus from './images/Phosphorus.jpeg';
import DLD from './images/DLD.png';
import Recombine from './images/Recombine.png';
import MockQuestions from './images/MockQuestions.png';
import AngelaLamb from './images/AngelaLamb.png';
import Nousha from './images/Nousha.png';

function Writing() {
  return (
    <div className="writing">
      <div className='opener'>
        <p id="h1-writing">I have written extensively in diverse contexts throughout my career. I've been a marketing copywriter for print and web, a CEO ghostwriter, a team lead editor, an academic coach, and a content producer and manager. My writing spans everything from qualitative financial analysis and business proposals to creative fiction and sketches. <b>Click the links and images below to view samples for current and former clients, including:</b></p>
      </div>
      <div className='sub-division-arrow'>
        <img className="jf-resume-arrow-2" src={ JFResumeArrow } alt="" />
      </div>
      <hr></hr>
      <div className='sub-division'>
        <a href='https://sternspeakers.com/speakers/debora-spar/' target='_blank' rel='noreferrer' aria-label='Stern site'>
          <img className="stern-image" src={ Stern } alt="" />
        </a>
      </div>
      <div className='stern'>
        <h1><b>Stern Speakers, a Division of Stern Strategy Group</b></h1>
        <h4>My work for Stern includes speaker bios, email blasts, workshop pitches, and more based on client conversations. Visionaries and luminaries I've written for represent New York Times bestselling authors, TED speakers, Silicon Valley executives, space architects, VR pioneers, venture capitalists, robotics researchers, and professors from MIT, Stanford, and HBS. Check out these select profiles of&nbsp;
            <a href='https://sternspeakers.com/speakers/ariel-ekblaw/' target='_blank' rel='noreferrer' aria-label='Ariel Ekblaw'>Ariel Ekblaw</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/debora-spar/' target='_blank' rel='noreferrer' aria-label='Debora Spar'>Debora Spar</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/jeremy-bailenson/' target='_blank' rel='noreferrer' aria-label='Jeremy Bailenson'>Jeremy Bailenson</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/tommy-amaker/' target='_blank' rel='noreferrer' aria-label='Tommy Amaker'>Tommy Amaker</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/karim-lakhani/' target='_blank' rel='noreferrer' aria-label='Karim Lakhani'>Karim Lakhani</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/renee-richardson-gosline/' target='_blank' rel='noreferrer' aria-label='Renee Richardson Gosline'>Renee Richardson Gosline</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/thomas-malone/' target='_blank' rel='noreferrer' aria-label='Thomas Malone'>Thomas Malone</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/jeffrey-pfeffer/' target='_blank' rel='noreferrer' aria-label='Jeffrey Pfeffer'>Jeffrey Pfeffer</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/sinan-aral/' target='_blank' rel='noreferrer' aria-label='Sinan Aral'>Sinan Aral</a>,&nbsp;
            <a href='https://sternspeakers.com/speakers/sandra-sucher/' target='_blank' rel='noreferrer' aria-label='Sandra Sucher'>Sandra Sucher</a>, and&nbsp;
            <a href='https://sternspeakers.com/speakers/david-blake/' target='_blank' rel='noreferrer' aria-label='David Blake'>David Blake</a>.
        </h4>
      </div>
      <div className='side-by-side'>
        <a href='https://www.quanterix.com/the-paradigm-shift-that-is-here-for-oncology/' target='_blank' aria-label='Quanterix site'>
          <img className="quanterix-image" src={ Quanterix } alt="" />
        </a>
        <a href='https://poweringprecisionhealth.org/science-to-society-modeling-the-precision-health-movement-on-the-war-on-cancer/' target='_blank' aria-label='PPH site'>
          <img className="pph-image" src={ PPH } alt="" />
        </a>
      </div>
      <div className='quanterix-pph'>
        <h1><b>Quanterix / Powering Precision Health</b></h1>
        <h4>I ghostwrote book material, CEO-POV blog content, and press releases, while simultaneously improving the social presence of this NASDAQ-traded company (Quanterix) and its burgeoning non-profit arm (Powering Precision Health). Check out these blog posts for&nbsp;
            <a href='https://www.quanterix.com/the-paradigm-shift-that-is-here-for-oncology/' target='_blank' aria-label='Quanterix site'>Quanterix</a>,&nbsp;and&nbsp;
            <a href='https://poweringprecisionhealth.org/science-to-society-modeling-the-precision-health-movement-on-the-war-on-cancer/' target='_blank' aria-label='Powering Precision Health'>Powering Precision Health</a>.
        </h4>
      </div>
      <div className='phosphorus'>
        <a href='https://phosphorus.com/blog/phosphorus-launches-genetic-tests-for-inherited-cardiology-diseases-associated-with-sudden-death-and-high-cholesterol' target='_blank' aria-label='Phosphorus site'>
          <img className="phosphorus-image" src={ Phosphorus } alt="" />
        </a>
      </div>
      <div className='phosphorus-text'>
        <h1><b>Phosphorus</b></h1>
        <h4>At Phosphorus, I wrote press releases, CEO articles, technical genetics documents, sales collateral, and web copy. I managed a robust blog and social presence featuring staff writers, sub-blogs from various departments, and external contributors through partnerships with organizations like Children’s Cardiomyopathy Foundation, The FH Foundation, Duke Center for Applied Genomics and Precision Medicine, The Annie Parker Foundation, The Kittim N. Sherrod Foundation, and SADS Foundation. Check out a select press release&nbsp;
            <a href='https://phosphorus.com/blog/phosphorus-launches-genetic-tests-for-inherited-cardiology-diseases-associated-with-sudden-death-and-high-cholesterol' target='_blank' aria-label='Phosphorus site'>here</a>.
        </h4>
      </div>
      <div className='dld'>
        <a href='https://directlinedev.com/' target='_blank' aria-label='DLD site'>
          <img className="dld-image" src={ DLD } alt="" />
        </a>
      </div>
      <div className='dld-text'>
        <h1><b>Direct Line Development</b></h1>
        <h4>I worked as part of a team of copywriters to create persuasive <a href='https://directlinedev.com/' target='_blank' aria-label='DLD site'>web content</a> for the clients of a web development agency.</h4>
      </div>
      <div className='recombine'>
        <a href='' target='_blank' aria-label='Recombine site'>
          <img className="recombine-image" src={ Recombine } alt="" />
        </a>
      </div>
      <div className='recombine-text'>
        <h1><b>Recombine</b></h1>
        <h4>At Recombine, I worked as a content strategist to ensure consistency of brand and messaging across company materials. In 2016, we sold Recombine to Fortune 500 company CooperSurgical.</h4>
      </div>
      <div className='mockquestions'>
        <a href='https://www.mockquestions.com/' target='_blank' aria-label='MockQuestions site'>
          <img className="mockquestions-image" src={ MockQuestions } alt="" />
        </a>
      </div>
      <div className='mockquestions-text'>
        <h1><b>MockQuestions</b></h1>
        <h4>I consulted for the CEO of an interview prep website to help optimize sales through copy and page design. Click through to the website <a href='https://www.mockquestions.com/' target='_blank' aria-label='MockQuestions site'>here</a>.</h4>
      </div>
      <div className='angela-lamb'>
        <a href='https://angelalambmd.com/' target='_blank' aria-label='Angela Lamb site'>
          <img className="angela-lamb-image" src={ AngelaLamb } alt="" />
        </a>
      </div>
      <div className='angela-lamb-text'>
        <h1><b>Angela Lamb, MD</b></h1>
        <h4>I consulted for dermatologist Angela Lamb, M.D., on her professional website and suite of patient materials. Click through to the website <a href='https://angelalambmd.com/' target='_blank' aria-label='MockQuestions site'>here</a>.</h4>
      </div>
      <div className='nousha'>
        <a href='https://www.rejuvenatewithnousha.com/' target='_blank' aria-label='Nousha site'>
          <img className="nousha-image" src={ Nousha } alt="" />
        </a>
      </div>
      <div className='nousha-text'>
        <h1><b>Rejuvenate with Nousha</b></h1>
        <h4>I consulted for facial rejuvenation artist Nousha Salimi, RN on various written assets for her business. Click through to the website <a href='https://www.rejuvenatewithnousha.com/' target='_blank' aria-label='MockQuestions site'>here</a>.</h4>
      </div>
      <h4 className='additional'>
          <i>(Without web presence: Teawareness, Bold Moves, University of Iowa Honors Writing Fellows)</i>
      </h4>
    </div>
  );
}

export default Writing;