import React from "react";
import './performance.css';
import Headshots from './images/Headshots.png';
import ActingResume from './images/JFActingResume.png';
import Iframe from 'react-iframe';

function Performance() {
  return (
    <div className="performance">
      <img className="headshots" src={ Headshots } alt="" />
      <img className="acting-resume" src={ ActingResume } alt="" />
      <hr></hr>
      <div id='header'><b>Uncredited camera work:</b></div>
      <ul>
          <li>McDonald’s regional commercial, Midwest</li>
          <li>Maytag training video</li>
          <li>Law &#38; Order SVU season 16, episode 14, “Intimidation Game,” on NBC</li>
          <li>Hightown season 1 on Starz</li>
          <li>The Politician season 2 on Netflix</li>
          <li>Ambition’s Debt, dir. Devin E. Haqq, available on Amazon Prime and iTunes</li>
          <li>Pose season 3 on FX</li>
          <li>FBI season season 2 on CBS</li>
          <li>The Last O.G. season 4 on TBS</li>
      </ul>
      <hr></hr>
      <div className='socials'><b>Social media clips:</b></div>
      <div className='instagrams'>
        <Iframe url="https://www.instagram.com/p/_f34D-uzUS/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BK1fn6vgby2/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/Bt1thSngxzx/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/Bi5l5YSAB15/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BZto6l0ndv8/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BZotUe-nIhK/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/9hKdHsuzfR/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/_BAOeQOzZI/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/Bi0VtjkAfU0/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BUdvM80huSO/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/Baq_6QPn-TK/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/3eWuoQuzUq/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BkTdTEjACip/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/BoIBqDkg8VW/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/p/Burk1WHgygY/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHba3PBD-p9/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHbc6Pkjgnf/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHbfHO-jc8z/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHeMc2JDwrY/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHbffhHDvJ2/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHhPkk2jazN/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CHjIts_DARI/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CH9k65nD-Z9/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CKj6avDjZy3/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CLIQrXXjIbW/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
        <Iframe url="https://www.instagram.com/reel/CPkC68Xlp-9/embed"
          width="300px"
          height="350px"
          id="myId"
          className="instagram"
          display="initial"
          position="relative"
        />
      </div>
      <div className='videos'><b>"The Longest Time," University of Iowa Intersection spring concert:</b></div>      
      <iframe className='iframe' width="70%" height="auto" src="https://www.youtube.com/embed/DK1aA6FFTIo?start=51" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      
      <div className='videos'><b>"Insomniac," University of Iowa Intersection spring concert:</b></div>
      <iframe className='iframe' width="560" height="315" src="https://www.youtube.com/embed/NjjuyDK2Sqo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      
      <div className='videos'><b>"Green Rooms," University of Iowa 2012, MFA Thesis Performance by Melinda Myers</b></div>
      <iframe className='iframe-small' src="https://player.vimeo.com/video/118156759?app_id=122963" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="GREEN ROOMS"></iframe>

      <div className='videos'><b>"Soul with a Capital S" solo, Urbandale Studio 2008</b></div>
      <iframe className='iframe' width="560" height="315" src="https://www.youtube.com/embed/IO8uEd7Tmao" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <div className='videos'><b>"Faith," University of Iowa Intersection private performance:</b></div>
      <iframe className='iframe' width="560" height="315" src="https://www.youtube.com/embed/RWGd-Xe2hb4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     
      <div className='videos'><b>"Fences," official selection—Best of NY: New York Film Anthology Archives (shot on location in rural Iowa)</b></div>
      <iframe className='iframe-small' src="https://player.vimeo.com/video/7747664?app_id=122963" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="Fences"></iframe>
    </div>
  );
}

export default Performance;